<template>
  <div class="map-wrapper">
    <!--    <div style="overflow: auto;">-->
    <!--      <p>Center is at {{ currentCenter }} and the zoom is: {{ currentZoom }}</p>-->
    <!--    </div>-->
    <l-map class="map" :zoom="zoom" :center="coords" :options="mapOptions" @update:center="centerUpdate">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker :lat-lng="coords" @drag="markerDrag" :options="{ draggable: 'true' }">
        <l-tooltip :options="{ permanent: true, interactive: true }">
          <div>
            {{ name }}
          </div>
        </l-tooltip>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { latLng } from 'leaflet';
import {
  LMap, LTileLayer, LMarker, LTooltip
} from 'vue2-leaflet';

export default {
  name: 'MapLocation',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  data() {
    return {
      zoom: 16,
      // в l-map :center прокидываем координаты не из computed coords, чтобы не было рекативности
      // иначе при перемещении метки центр карты ездит за ней
      // А если хотим чтобы центр ездил за меткой, используем computed coords
      // center: latLng(this.coordsLat, this.coordsLng),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      // withTooltip: latLng(this.coordsLat, this.coordsLng),
      currentZoom: 16,
      currentCenter: latLng(this.coordsLat, this.coordsLng),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.25
      }
    };
  },
  props: {
    coordsLng: Number,
    coordsLat: Number,
    name: String
  },
  computed: {
    coords() {
      return latLng(this.coordsLat, this.coordsLng);
    }
  },
  methods: {
    centerUpdate(center) {
      this.currentCenter = center;
    },
    markerDrag(event) {
      // console.log(event);
      const newCoords = event.latlng;
      this.$emit('markerDrag', newCoords);
    }
  },
  mounted() {
    // хак для прорисовки карты, без этого иногда рендерится только маленький кусок карты
    // https://github.com/vue-leaflet/Vue2Leaflet/issues/96#issuecomment-341459943
    // eslint-disable-next-line
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 250);
  }
};
</script>

<style lang="scss" scoped>
.map-wrapper {
  height: 250px;
  width: 100%;
}
.map {
  height: 100%;
}
</style>
